<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/" custom v-slot="{}">
            <a @click="cancelModal">Cancel</a>
          </router-link>
        </ion-buttons>
        <ion-title>Filter Bookings</ion-title>
        <ion-buttons slot="end">
          <ion-button :strong="true" @click="setFilters()">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="padding-y-16">
        <h4 class="ion-padding-start">Booking Status</h4>
        <ion-list>
          <ion-item>
            <ion-checkbox
              slot="start"
              :checked="filters.cancelled"
              value="cancelled"
              @ionChange="handleCheck($event)"
            >
            </ion-checkbox>
            <ion-label>Cancelled</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox
              slot="start"
              :checked="filters.refunded"
              value="refunded"
              @ionChange="handleCheck($event)"
            >
            </ion-checkbox>
            <ion-label>Refunded</ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox
              slot="start"
              :checked="filters.confirmed"
              value="confirmed"
              @ionChange="handleCheck($event)"
            >
            </ion-checkbox>
            <ion-label>Confirmed</ion-label>
          </ion-item>
        </ion-list>
      </div>

      <ion-button
        expand="block"
        fill="solid"
        class="padding-x-16 margin-bottom-24"
        @click="setFilters"
        >Save</ion-button
      >
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
} from "@ionic/vue";

export default {
  props: {
    existingFilters: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonCheckbox,
    IonLabel,
  },

  data() {
    return {
      filters: {
        cancelled: false,
        refunded: false,
        confirmed: false,
      },
    };
  },

  mounted() {
    this.filters = { ...this.existingFilters };
  },

  methods: {
    async handleCheck(event) {
      this.filters[event.detail.value] = event.detail.checked;
    },

    setFilters() {
      this.$emit("set-filters", this.filters);
    },

    cancelModal() {
      this.$emit("cancel-modal");
    },
  },
};
</script>