<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label>
      <div class="h6">
        {{ engagement.user.first_name }} {{ engagement.user.last_name }}
      </div>
      <div>
        <p class="ion-text-capitalize">
          {{ engagement.sessions_day }} Day Sessions / {{ engagement.sessions_night }} Night Sessions
        </p>
      </div>
    </ion-label>

    <!-- Right Side -->
    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <!-- Status Chip -->
      <ion-chip :color="(engagement.status === 'confirmed') ? 'primary' : 'warning'">
        <ion-label class="ion-text-capitalize">{{ engagement.status }}</ion-label>
      </ion-chip>

      <div class="small">
      </div>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonChip } from "@ionic/vue";

export default {
  props: {
    engagement: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonLabel,
    IonChip,
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>