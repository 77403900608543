<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/" custom v-slot="{}">
            <a @click="cancelModal">Cancel</a>
          </router-link>
        </ion-buttons>
        <ion-title>Engagements Search</ion-title>
        <ion-buttons slot="end"> </ion-buttons>
      </ion-toolbar>

      <ion-toolbar>
        <ion-searchbar
          v-model="searchTerm"
          debounce="500"
          :autofocus="true"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list class="ion-padding-start ion-padding-end">
        <engagement-list-item
        v-for="engagement in engagements"
        v-bind:key="engagement.id"
        :engagement="engagement"
        @click="selectEngagement(engagement)"
      ></engagement-list-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonButtons,
  IonContent,
  IonList,
} from "@ionic/vue";
import EngagementListItem from "@/components/engagements/EngagementListItem.vue"

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSearchbar,
    IonButtons,
    IonContent,
    IonList,
    EngagementListItem
  },

  data() {
    return {
      searchTerm: null,
      engagements: [],
      engagement: {},
    };
  },

  watch: {
    searchTerm() {
      this.fetchEngagements(this.searchTerm);
    },
  },

  ionViewWillEnter() {
    this.fetchEngagements();
  },

  methods: {
    async fetchEngagements() {
    },

    selectEngagement(engagement) {
      this.engagement = engagement;
      this.$emit("select-engagement", this.engagement);
    },

    cancelModal() {
      this.$emit("cancel-modal");
    },
  },
};
</script>