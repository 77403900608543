<template>
  <base-layout-one page-title="At Home Engagements" content-class="background-white">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <section class="ion-padding-top ion-padding-start ion-padding-end">
      <ion-button class="regular-button" expand="block" router-link="/engagements/create">New Engagement</ion-button>
    </section>

    <section class="padding-x-8">
      <ion-searchbar placeholder="Search Engagements" @click="showEngagementSearchModal = true"></ion-searchbar>
    </section>

    <!-- Segments -->
    <section class="padding-x-16">
      <ion-segment scrollable value="all" @ionChange="handleSegmentClick($event)">
        <ion-segment-button value="all">Coordinating</ion-segment-button>
        <ion-segment-button value="spa">Pending</ion-segment-button>
        <ion-segment-button value="workshop">In-Progress</ion-segment-button>
        <ion-segment-button value="community">Completed</ion-segment-button>
        <ion-segment-button value="community">All</ion-segment-button>
      </ion-segment>
    </section>

    <section class="padding-x-16 margin-top-8">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <!-- Left -->
          <ion-col size="12">
            <div class="ion-float-left">
              <ion-button fill="solid" size="small" @click="showEngagementFilterModal = true">
                Filters ({{ filtersCount }})
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <!-- Engagements List View -->
    <div>
      <ion-list class="padding-x-16">
        <engagement-list-item v-for="engagement in filteredEngagements" :key="engagement.id"
          :engagement="engagement" :router-link="`/engagements/${engagement.id}`">
        </engagement-list-item>
      </ion-list>
    </div>

    <!-- Engagements Search Modal -->
    <ion-modal :is-open="showEngagementSearchModal" @didDismiss="() => {
      showEngagementSearchModal = false;
    }
      ">
      <engagement-search-modal @select-engagement="selectEngagement"
        @cancel-modal="() => (this.showEngagementSearchModal = false)"></engagement-search-modal>
    </ion-modal>

    <!-- Engagement Filter Modal -->
    <ion-modal ref="modal" :is-open="showEngagementFilterModal" @didDismiss="() => {
      showEngagementFilterModal = false;
    }
      ">
      <engagement-filter-modal :existingFilters="filters" @set-filters="setFilters"
        @cancel-modal="() => (this.showEngagementFilterModal = false)"></engagement-filter-modal>
    </ion-modal>
  </base-layout-one>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonList,
  IonButton,
  // IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSearchbar,
  IonModal,
} from "@ionic/vue";
import {
  filterCircleOutline,
} from "ionicons/icons";
import EngagementListItem from "@/components/engagements/EngagementListItem.vue";
import EngagementSearchModal from "@/components/engagements/EngagementsSearchModal.vue";
import EngagementFilterModal from "@/components/engagements/EngagementFilterModal.vue";
// import { format } from "date-fns";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonButton,
    // IonIcon,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    IonModal,
    EngagementListItem,
    EngagementSearchModal,
    EngagementFilterModal,
  },

  data() {
    return {
      filteredEngagements: [],
      engagements: [],
      filterCircleOutline,
      showEngagementSearchModal: false,
      showEngagementFilterModal: false,
      filters: {
        coordinating: true,
        pending: false,
        in_progress: false,
        complete: false,
      },
    };
  },

  watch: {},

  computed: {
    filtersCount() {
      return Object.values(this.filters).filter((x) => x).length;
    },
  },

  ionViewWillEnter() {
    this.fetchEngagements();
  },

  methods: {
    async refresh(event) {
      await this.fetchEngagements();

      event.target.complete();
    },

    async fetchEngagements() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/engagements`)
        .then((response) => {
          this.engagements = response.data.engagements;

          this.filterEngagements()
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectEngagement(engagement) {
      this.showEngagementSearchModal = false;
      this.$router.push(`/engagements/${engagement.id}`);
    },

    setFilters(filters) {
      this.filters = filters;
      this.showEngagementFilterModal = false;
      this.filterEngagements();
    },

    filterEngagements() {
      this.filteredEngagements = this.engagements.filter((engagement) => {
        if (
          (this.filters.coordinating && engagement.status === "coordinating") ||
          (this.filters.pending && engagement.status === "pending") ||
          (this.filters.in_progress && engagement.status === "in_progress") ||
          (this.filters.completed && engagement.status === "completed")
        )
          return true;

        return false;
      });

      console.log(this.filteredEngagements);
    },

    handleSegmentClick(event) {
      if (!event) this.filteredEngagements = this.engagements;
      else if (event.detail.value === "all")
        this.filteredEngagements = this.engagements;
      else
        this.filteredEngagements = this.engagements.filter((engagement) => {
          return engagement.status === event.detail.value;
        });
    },
  },
};
</script>

<style scoped></style>